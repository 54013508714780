import React from "react";
//= Layout
import MainLayout from "layouts/Main";
//= Components
import Navbar from "components/Navbars/ITCreativeNav";
import Blog from "components/Software/Blog";
import ChatBanner from "components/Software/ChatBanner";
import Footer from "components/Saas/Footer";
import { Link } from "gatsby";
import Blog5 from "components/Software/Blog5";
import Codei from "components/Preview/Codei";

const MtPlusLoan = () => {
  return (
    <MainLayout>
      <Navbar />
      <header className="style-3 overflow-hidden" data-scroll-index="0" style={{background: '#000'}}>
        <div className="container">
          <div className="content section-padding">
            <div className="row">
              <div className="col-lg-5">
                <div className="info" style={{ background: '#00000000'  }}>
                  <h1 className="h1">
                    Fast loans for Private Sector <span> employees</span>
                  </h1>
                  <p className="p">
                    Settle your financial needs with ease and fast.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-img ">
          <img src="/assets/img/about/style_3_1.png" style={{ maxWidth: '30%'}} alt="" />
          <img
                    src="/assets/img/about/MTPLUS.png"
                    alt=""
                    className="img-body"
                  />
        </div>
      </header>
      <main>
        <section
          className="about section-padding style-3"
          data-scroll-index="3"
        >
          <div className="container">
            <div className="row gx-0 justify-content-end">
              <div className="col-lg-6">
                <div className="img">
                  <img
                    src="/assets/img/about/MTPLUS.png"
                    alt=""
                    className="img-body"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="">
                  <div className="section-head long-shape mb-40 style-3">
                    <h3>
                      MTPlus <span> Loan</span>
                    </h3>
                  </div>
                  <p>
                    Looking for a loan with flexible terms and low interest
                    rates? The MTPlus Loan is perfect for Private salaried
                    individuals, offering personal loans for various needs. From
                    home improvement to debt consolidation, MTPlus Loan is
                    designed to support your personal financial goals.
                  </p>

                  <div className="text mb-20 mt-20">
                    <h5>Requirements: </h5>
                    <ul>
                      <li>Private Sector Employee</li>
                      <li>Solid Guarantor</li>
                      <li>A Good Netpay</li>
                      <li>BVN</li>
                    </ul>
                  </div>

                  <Link
                    to="/#"
                    className="btn rounded-pill bg-blue2 sm-butn text-white mt-10"
                  >
                    <span>Apply for MTPlus Loan</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Blog5 />
        <Codei /> 
        <ChatBanner />
      </main>
      <Footer />
    </MainLayout>
  );
};

export const Head = () => {
  return (
    <>
      <title>Mutual Trust MFB</title>
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  );
};

export default MtPlusLoan;
